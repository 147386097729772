


































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import { User } from '@/types';
import {
  Vue, Component, Watch,
} from 'vue-property-decorator';

@Component
export default class HelpCreditsDialog extends Vue {
  dialog = false
  loading = false

  get value(): boolean {
    return this.$store.getters.helpCreditsDialog;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    const debugPrefix = 'HelpCreditsDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'HelpCreditsDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'helpCredits');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onCancel() {
    this.dialog = false;
  }
}
